import {isDev} from "@/common/other";

export const site_config = {
    title:process.env.VUE_APP_TITLE,
    cookie_prefix:process.env.VUE_APP_COOKIE_PREFIX || 'cookie',
    json_host:process.env.VUE_APP_JSON_HOST,
    json_dir:process.env.VUE_APP_JSON_DIR,
}
export const ext_config = {
    file:".pdf,.ppt,.doc,.docx,.word,.xlxs,.xls",
    image:".jpg,.jpeg,.png,.gif,.svg,.tiff",
    video:".mp4,.mov,.ogg",
}

export const host_config = {
    api:isDev() ? process.env.VUE_APP_API_HOST : process.env.VUE_APP_API_HOST,
}

export const book_config = {
    book:"https://launchevent2022.lenovonetapp.com/s/template/100377/1erPx1cCU3/index.html?customFormId=2211243265604822&instanceId=875594&linkId=329003&configId=3215723&tc={tc}#/pc_index?randNum=1669260645",
    login:"https://launchevent2022.lenovonetapp.com/s/template/100377/1erPx1cCU3/index.html?customFormId=2211243265604822&instanceId=875594&linkId=329003&configId=3215723&tc={tc}#/pc_verify?randNum=1669260645"
}
