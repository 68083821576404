import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import directive from "@/directive";
import plugins from "@/plugins";

Vue.config.productionTip = false

new Vue({
    router,
    store,
    directive,
    plugins,
    render: h => h(App)
}).$mount('#app')
