<template>
	<main>
		<div class="upload">
			<el-input class="upload-box" v-model="modelValue" clearable>
				<template #append>
					<div class="btn-box">
						<div class="upload-btn">
							<el-button class="btn" icon="Plus" type="" :loading="upload_loading" >上传</el-button>
							<input class="input" type="file" :accept="accept_list" @change="chooseFile"/>
						</div>
<!--						<div class="choose-btn">
							<el-button class="btn" icon="Aim" type="primary"  @click="show_attachment = true">选择</el-button>
						</div>-->
					</div>
				</template>
			</el-input>
		</div>
		<div class="preview" v-if="value_array.length > 0">
			<div class="item" v-for="(item,index) in value_array" :key="index">
				<img class="image" v-if="isImage(item)" :src="item" alt="">
				<a v-else :href="item" class="link">
					<span class="icon"><i class="t">{{fileName(item)}}</i></span>
				</a>
				<div class="act">
					<div class="wrap">
						<i class="icon el-icon-view" @click="preview(item)"></i>
						<i class="icon el-icon-delete" @click="removeFile(index)"></i>
					</div>
				</div>
			</div>
		</div>
		<component-attachment v-if="show_attachment" :accept="accept_list" @close="show_attachment = false" @choose="choose"/>
		<component-cropper v-if="cropper_visible" :size="size" ref="cropperRef" @success="cropperSuccess" @error="cropperError"/>
		<component-preview v-if="preview_visible" :images="preview_image" @closeViewer="closeViewer"/>
	</main>
</template>

<script >
import ComponentCropper from "@/components/admin/form/upload/Cropper.vue"
import ComponentPreview from "@/components/admin/form/upload/Preview.vue"
import ComponentAttachment from "@/components/admin/form/upload/Attachment.vue"
import {site} from "@/api/admin";
import {ext_config} from "@/config/index.js";
import {inArray} from "@/common/array";
import axios from "axios";

export default {
	components:{ComponentCropper,ComponentPreview,ComponentAttachment},
	props:{
		value:"",
		limit:{
			type:Number,
			default:1
		},
		accept:{
			type:String,
			default:'*'
		},
		size:{
			type:String,
			default:""
		}
	},
	data(){
		return {
			show_attachment:false,

			upload_loading:false,
			upload_disable:false,

			cropper_visible:false,
			preview_visible:false,
			preview_image:"",

			ext:"",

			defaultImage: ''
		}
	},
	computed:{
		modelValue:{
			get(){
				return this.value
			},
			set(value){
				this.$emit('input',value)
			}
		},
		value_array(){
			return this.value ? this.value.split(',') : []
		},
		accept_list(){
			var accept_list;
			if(!this.accept || this.accept === '*'){
				accept_list = "*"
			}else if(inArray(['file','image','video'],this.accept)){
				accept_list = ext_config[this.accept];
			}else{
				accept_list = this.accept;
			}
			return accept_list;
		}
	},
	methods:{
		fileName(file){
			var index = file.lastIndexOf(".");
			var ext = file.substring(index+1);
			return ext.substring(0,5)
		},
		cropperSuccess(file){
			this.cropper_file = file;
			this.cropper_visible = false;
		},
		cropperError(message){
			this.$message.error(message);
			this.cropper_visible = false;
		},
		checkFileType(name){
			var index= name.lastIndexOf(".");
			var ext = name.substring(index+1);
			this.ext = ext;
			var accept_list = this.accept_list.split(',');
			return inArray(accept_list,'*') || inArray(accept_list,'.'+ext);
		},
		chooseFile(event){
			var file = event.target.files[0];
			document.querySelector('.input').value = "";

			if(!this.checkFileType(file.name)){
				this.$message.error('文件格式不正确');
				return  false;
			}

			this.cropper_file = '';
			if(!this.size || !this.canCropper(file.name)){
				this.cropper_file = file;
				this.doUpload();
			}else{
				this.cropper_visible = true;
				this.$nextTick(()=>{
					this.$refs['cropperRef'].init(file);

					clearInterval(this.timer);
					this.timer = setInterval(()=>{
						if(this.cropper_file){
							clearInterval(this.timer);
							this.doUpload()
						}
					},300);

					this.$once('hook:beforeDestroy',()=>{
						clearInterval(this.timer);
					})
				})
			}
			return false;
		},
		doUpload(){
			this.upload_loading = true;
			site.oss({
				dir:"lingtuo/uploads/"
			}).then((res)=>{
				var config = res.config;
				var filename = config.filename +'.'+this.ext;
				var formData = new FormData();
				formData.append('key', config.dir + filename);
				formData.append('policy', config.policy);
				formData.append('OSSAccessKeyId', config.accessid);
				formData.append('success_action_status', 200);
				formData.append('signature', config.signature);
				formData.append('file', this.cropper_file);
				axios.post(config.host,formData).then((res)=>{
					const url = config.cdn + '/' + config.dir+filename;
					let list = this.value ? this.value.split(',') : [];
					if(this.limit > 1){
						list.push(url);
					}else{
						list = [url]
					}
					this.change(list.join(','))
				}).finally(()=>{
					this.upload_loading = false;
				})
			})
			// this.upload_loading = true;
			// var formData = new FormData();
			// formData.append('file',this.cropper_file);
			// site.upload(formData).then((res)=>{
			// 	let list = this.value ? this.value.split(',') : [];
			// 	if(this.limit > 1){
			// 		list.push(res.url);
			// 	}else{
			// 		list = [res.url]
			// 	}
			// 	this.change(list.join(','))
			// }).finally((res)=>{
			// 	this.upload_loading = false;
			// })
		},
		closeViewer() {
			this.preview_visible = false;
			this.preview_image = "";
		},
		preview(url) {
			if(this.isImage(url)){
				this.preview_image = [url];
				this.preview_visible = true;
			}else{
				window.open(url,'_blank')
			}
		},
		isImage(url){
			if(!url){return  false;}
			url = url.split('?')[0];
			var index= url.lastIndexOf(".");
			var ext = url.substr(index+1);
			var type = ext_config.image;
			return type.indexOf(ext.toLowerCase()) > -1;
		},

		canCropper(url){
			var index= url.lastIndexOf(".");
			var ext = url.substr(index+1);
			var type = ['png', 'jpg', 'jpeg'];
			return type.indexOf(ext.toLowerCase()) > -1;
		},
		removeFile(index,url){
			const list = this.value ? this.value.split(',') : [];
			list.splice(index,1);
			this.change(list.join(','));
		},
		choose(url){
			this.show_attachment = false;
			let list = this.value ? this.value.split(',') : [];
			if(this.limit > 1){
				list.push(url);
			}else{
				list = [url]
			}
			this.change(list.join(','));
		},
		change(list){
			this.$emit('input',list);
		},
	}
}
</script>

<style lang="scss" scoped>
.upload-box{
	::v-deep{
		.el-input-group__append{padding: 0;
			.el-button{margin: 0;}
			.el-button--primary{background: #66b1ff;color:#fff;}
		}
	}
	.btn-box{display: flex;height:100%;
		.upload-btn{position: relative}
		.input{position: absolute;top:0;left:0;width: 100%;height:100%;opacity: 0;
		}
	}
}
.preview{display: flex;flex-wrap: wrap;
	.item{width:120px;height:100px;border: 1px solid #ccc;border-radius: 5px;position: relative;margin-right: 10px;margin-top: 10px;display: flex;align-items: center;justify-content: center;
		.image{display: block;width:90%;height:90%;object-position:center center;object-fit: contain;}
		.link{display: block;width: 100%;height:100%;
			.icon{display: block; width: 88px;height:90px;margin: 5px auto;position: relative;background: url("~@/assets/admin/image/file.png") no-repeat center center;background-size: 100% 100%;
				.t{position: absolute;top:40px;left:0;width: 70px;text-align: center; height:40px;line-height: 40px;font-size: 18px;font-weight: bold;color:#333;text-transform: uppercase;}
			}
		}
		.act{position: absolute;top:0;left:0;width: 100%;height:100%;display: flex;align-items: center;justify-content: center;background: rgba(0,0,0,.5);opacity: 0;transition: all .3s ease;
			.icon{width: 22px;height:22px;margin-right: 15px;color:#fff;font-size: 20px;cursor: pointer;}
			.icon:nth-last-child(1){margin-right: 0;}
		}
	}
	.item:hover{
		.act{opacity: 1;transition: all .3s ease}
	}
}
</style>

